/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Incidents.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.button {
  min-width: 200px;
  font-size: 12px;
  font-weight: bold;
}

.createButton {
  width: 160px;
}

.link {
  color: $primary;
  cursor: pointer;
}
