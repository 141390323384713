/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Panel.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.wrapper {
  padding: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid $grey;
  }
}

.title {
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
}

.subPanel {
  &:not(:first-child) {
    padding-top: 10px;
  }
}
