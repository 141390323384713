/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: TrComponent.module.scss
*
* @author: Panasonic, developer
*
*/

@import 'src/styles/common.scss';

$trBorder: 1px solid $grey;

.tr.expanded {
  z-index: 12;
  box-shadow: 0 2px 5px 0 $grey;
}
