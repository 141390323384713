@import 'src/styles/_colors';

.root {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 13px;
  font-weight: bold;
}

.description {
  margin-top: 5px;
  font-size: 12px;
  color: $caption-grey;
}
