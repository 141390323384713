/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: StickyWrapper.module.scss
 *
 * @author: Panasonic, developer
 */

.sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 11;

  &.bottom {
    bottom: 0;
  }

  &.top {
    top: 0;
  }
}

.stickyFallback {
  z-index: 11;
  transform: none !important;
}
