/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: OptionsPanel.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.searchWrapper {
  padding: 10px;
  border-bottom: 1px solid $light-grey3;
}

.search {
  border-color: $light-grey3 !important;
}

.tools {
  padding: 10px;
  border-top: 1px solid $light-grey3;
}

.noResults {
  padding: 15px 0;
  font-size: 13px;
  text-align: center;
}

.emptyState {
  height: 170px;
}

.emptyStateLabel {
  margin-top: 5px;
  color: $grey;
  font-size: 13px;
}
