@import 'src/styles/_colors.scss';

.item {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status {
  margin-left: auto;
}

.title {
  font-weight: 200;
  font-size: 13px;
  line-height: 20px;
}
