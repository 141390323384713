/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: NavigationItem.module.scss
 *
 * @author: Panasonic, developer
 *
 */

@import 'src/styles/common.scss';

@keyframes slide-in {
  100% {
    transform: translateY(0%);
  }
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  margin: 0 20px 0 10px;
  color: $white;
  font-size: 15px;
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }

  &:global(.active) {
    position: relative;
    font-weight: bold;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 2px;
      animation: slide-in 0.5s forwards;
      background: $white;
      content: '';
      transform: translateY(100%);
    }
  }
}
