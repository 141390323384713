/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: ButtonGroup.module.scss
*
* @author: Panasonic, developer
*
*/

@import 'src/styles/common.scss';

.wrapper {
  display: flex;
  overflow: hidden;
  border-radius: 2px;

  & > button {
    border-radius: 0;
  }
}

.expanderWrapper {
  display: flex;
  width: 40px;
  height: 38px;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: -1px;
  border-radius: 0;
}

.expander {
  pointer-events: none;
}

div.menu {
  border: 0;
  border-radius: 2px !important;
}

.placement {
  &Top {
    margin-top: -10px;
  }

  &Bottom {
    margin-top: 10px;
  }
}

.hiddenButtonsContainer {
  display: flex;
  flex-direction: column;
}

.containedExpander {
  border-left: 1px solid $half-transparent !important;
}
