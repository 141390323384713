/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: Button.module.scss
*
* @author: Panasonic, developer
*
*/

@import 'src/styles/common.scss';

@mixin colors(
  $background: transparent,
  $border: transparent,
  $text: $black-main,
  $background-hover: $background,
  $border-hover: $border,
  $text-hover: $text
) {
  border: 1px solid $border;
  background: $background;
  color: $text;

  &:hover {
    background: $background-hover;
    border-color: $border-hover;
    color: $text-hover;
  }
}

.wrapper {
  @include colors();

  display: inline-flex;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  outline: none;
  text-decoration: none;

  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }
}

.default {
  &.primary {
    @include colors($text: $primary, $text-hover: $primary-dark);
  }

  &.secondary {
    @include colors($text: $secondary, $text-hover: $white);
  }
}

.outlined {
  &.default {
    @include colors(
      $border: $default-main,
      $text: $black-main,
      $background-hover: $default-high-transparent,
      $border-hover: $default-light,
      $text-hover: $black-light
    );
  }

  &.primary {
    @include colors(
      $border: $primary,
      $text: $primary,
      $background-hover: $white,
      $border-hover: $primary-dark,
      $text-hover: $primary-dark
    );
  }

  &.secondary {
    @include colors(
      $border: $secondary,
      $text: $secondary,
      $background-hover: $high-transparent,
      $border-hover: $white,
      $text-hover: $white
    );
  }
}

.contained {
  &.default {
    @include colors(
      $background: $default-main,
      $border: $default-main,
      $text: $black-main,
      $background-hover: $default-light,
      $border-hover: $default-light
    );
  }

  &.primary {
    @include colors(
      $background: $primary,
      $border: $primary,
      $text: $white,
      $background-hover: $primary-dark,
      $border-hover: $primary-dark,
      $text-hover: $white
    );
  }

  &.secondary {
    @include colors(
      $border: $secondary,
      $text: $primary,
      $background-hover: $white,
      $border-hover: $primary-light,
      $text-hover: $primary-light
    );
  }
}

.inline {
  display: inline-block;
  min-width: auto;
  padding: 0 !important;
  font-size: 13px;
  letter-spacing: normal;
  line-height: 20px;

  &.default {
    @include colors(
      $background: transparent,
      $border: transparent,
      $text: $black,
      $text-hover: $primary,
      $background-hover: transparent,
      $border-hover: transparent
    );
  }

  &.primary {
    @include colors(
      $background: transparent,
      $border: transparent,
      $text: $primary,
      $background-hover: transparent,
      $border-hover: transparent,
      $text-hover: $primary-light
    );
  }

  &.secondary {
    @include colors(
      $border: transparent,
      $text: $secondary,
      $background-hover: transparent,
      $border-hover: transparent,
      $text-hover: $white
    );
  }
}

.small {
  padding: 7px 8px;
}

.medium {
  height: 38px;
  padding: 0 10px;
}

.large {
  padding: 11px 24px;
}

.fullSize {
  width: 100%;
  height: 100%;
  padding-right: 0;
  padding-left: 0;
}

.upperCase {
  text-transform: uppercase;
}

.fab {
  border-radius: 50%;
}

.centered {
  align-items: center;
  justify-content: center;
}

button.withSpinner {
  align-items: center;
  justify-content: center;
  padding: 0;
  pointer-events: none;
}

div.spinner {
  &Outlined {
    &.primary,
    &.secondary {
      color: $primary;
    }
  }

  &Contained {
    &.primary {
      color: $white;
    }
  }
}
