/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: FooterActions.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

@keyframes slide-up {
  100% {
    transform: translateY(0%);
  }
}

.wrapper {
  width: 100%;
  height: $footerActionsHeight;
  padding: 40px 0;
  border-top: 1px solid $grey;
  animation: slide-up 0.5s forwards;
  background: $white;
  transform: translateY(100%);
}

.buttonGroupWrapper {
  display: flex;
  flex-grow: 1;

  &Embedded {
    max-width: 200px;
  }
}

.button {
  min-width: 160px;
  justify-content: center;

  &Embedded {
    min-width: 0;
    max-width: 200px;
    flex-grow: 1;
  }

  &:not(:first-child) {
    margin-left: 20px;
  }
}

.groupButton {
  &Embedded {
    max-width: 200px;
    flex-grow: 1;
  }
}

.groupButtonPaper {
  &Embedded {
    min-width: 200px !important;
    max-width: 200px !important;
  }
}

.hiddenButton,
.hiddenButton:not([disabled]):hover {
  max-height: 38px;
  border-radius: 0;
}

.groupedButton {
  justify-content: flex-start;
  margin-left: 0 !important;

  &:not(:last-of-type),
  &:not(:last-of-type):hover {
    border-bottom: none !important;
  }
}

.shortButton {
  min-width: 120px;
}

.content {
  display: flex;
  composes: route-container from global;
  composes: right-aligned-items from global;

  & > .button {
    animation: fadeInUp 0.5s ease-in-out;
  }

  &.contentEmbedded {
    width: 100%;
    padding: 0 20px;
  }
}

.simpleActions {
  z-index: 11;
  display: flex;
  height: 120px;
  align-items: center;
  border-top: 1px solid $grey;
  background-color: $white;
}

.simpleActionsContent {
  display: flex;
  composes: route-container from global;
}

.stickyWrapperRoot {
  z-index: $footerActionsZIndex;
}
