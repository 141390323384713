/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Errors.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.contained {
  position: absolute;
  top: 100%;
  margin-top: -17px;
  color: $red;
}

.global {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: $red;
  color: white;
}

.error {
  font-size: 11px;
}

.globalError {
  margin: auto;
  font-size: 12px;
}
