/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: CampaignSummary.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.wrapper {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  margin-bottom: 20px;
  composes: top-center-aligned-items from global;
}

.toggleButton {
  margin-top: 20px;
}

.panel:first-of-type {
  .panelItem:nth-of-type(2n) {
    margin-bottom: 10px;
  }
}

.card {
  background: $white;
  padding: 20px;
  border: 1px solid $grey;
}

.cardTitle {
  font-size: 14px;
  font-weight: bold;
}

.cardDescription {
  color: $caption-grey;
}

.statusBlock {
  border-top: none;
  margin-bottom: 20px;

  .cardTitle {
    font-size: 30px;
    line-height: 40px;
    font-weight: normal;
  }
}

.field {
  display: flex;
  font-size: 13px;
  line-height: 20px;

  &:nth-child(2n + 1) {
    margin-top: 10px;
  }
}

.fieldName {
  width: 50%;
  color: $caption-grey;
}

.fieldValue {
  width: 50%;
}
