/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: Table.module.scss
*
* @author: Panasonic, developer
*
*/

@import 'src/styles/common.scss';

$border: 1px solid $grey;

.container {
  position: relative;
}

.tableCell {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}

:global(.ReactTable) {
  &.tableWrapper {
    border: 0;
    color: $dark-grey;

    .table {
      max-width: 100%;
      border: $border;
      background-color: $white;
      overflow-x: overlay;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        height: 16px;
        background: $white;
      }

      &::-webkit-scrollbar-track-piece {
        box-shadow: -1px 0 0 0 $grey;
      }

      &::-webkit-scrollbar-thumb {
        border: 4px solid $white;
        background-color: $grey;
        border-radius: 16px;

        &:hover {
          background-color: $light-grey6;
        }

        &:active {
          background-color: $light-grey3;
        }
      }

      .tableCell {
        padding: 0 0 0 20px;
        border-right: none;
      }

      &.noData {
        border: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .thead {
      border-bottom: $border;
      box-shadow: none !important;

      .trGroup:last-of-type & {
        border-top: none;
      }
    }

    &.wrapperTable {
      div.thead {
        border-top: $border;
      }

      div.trGroup:last-of-type {
        .tr {
          border-bottom: $border;
        }
      }

      & > div.table {
        border: none;

        .tableCell {
          &:first-child {
            border-left: 1px solid $grey;
          }

          &:last-child {
            border-right: 1px solid $grey !important;
          }

          &.expandable {
            cursor: pointer !important;
          }
        }
      }
    }

    .trGroup {
      border-bottom-color: $grey !important;
    }

    .tbody {
      overflow: visible;
      width: 100%;
      max-width: 100%;

      &NoHead {
        border-top: $border;
      }

      &:empty {
        min-width: auto !important; // hide scrollbar for empty state in IE11
      }
    }

    & > .emptyState {
      width: 100%;
      padding: 20px;
    }

    &.subTable {
      div.trGroup:last-of-type {
        .tr {
          border-bottom: none;
        }
      }

      & div.table {
        background-color: $bg-main;

        &::-webkit-scrollbar {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-color: $bg-main;
        }

        .thead {
          border-top: none;
        }

        div.tableCell {
          &:first-child {
            border-left: none;
          }

          &:last-child {
            border-right: none !important;
          }
        }
      }

      border-bottom: 20px solid $bg-main;
    }
  }
}

.stickyColumn {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  background-color: $white;

  &:global(.rt-th) {
    z-index: 11;
  }

  &SubTable {
    background-color: $bg-main;
  }

  &Last {
    right: 0;
  }

  &LastTh {
    right: 0;
  }
}

.withBorderRight {
  border-right: 1px solid $grey !important;
}
