/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: RuleItem.module.scss
 *
 * @author: Panasonic, developer
 *
 */

@import 'src/styles/common.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.label {
  margin-left: 10px;
  color: $dark-grey;
  font-size: 11px;
  line-height: 16px;
}
