/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: LabelWithDescription.module.scss
*
* @author: Panasonic, developer
*
*/

@import 'src/styles/common.scss';

.root {
  max-width: 100%;
  font-size: 13px;
}

.label {
  color: $black;
  font-weight: 200;
  line-height: 20px;
}

.description {
  color: $grey;
  font-size: 13px;
  font-weight: 200;
  line-height: 20px;
  word-break: break-word;
}
