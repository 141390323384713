/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: _modificators.scss
*
* @author: Panasonic, developer
*
*/

@import './variables';

// TODO: remove redundant modificators after incidents management implementation
.-w100i {
  width: 100% !important;
}

.-full-width {
  width: 100%;
}

.-overflow-hidden {
  overflow: hidden;
}

.-overflow-visible {
  overflow: visible;
}

.-outline-none {
  outline: 0;
}

.-truncate {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.-break-word {
  overflow-wrap: break-word;
  word-break: break-all;
  word-wrap: break-word;
}

.-text-capitalized {
  text-transform: capitalize;
}

.-border-none {
  border: none !important;
}

.-no-padding {
  padding: 0 !important;
}

.-no-margin {
  margin: 0 !important;
}

.left-aligned {
  margin-left: auto;
}

.-no-events {
  pointer-events: none;
}

.default-font {
  font-size: $defaultFontSize;
}

.-no-pd-right {
  padding-right: 0;
}

.-no-pd-top {
  padding-top: 0;
}

.-no-pd-bottom {
  padding-bottom: 0;
}

.-pd-top-20 {
  padding-top: 20px;
}

.-pd-15 {
  padding: 15px;
}

.-block {
  display: block;
}

.-inherit-text-styles {
  color: inherit;
  text-decoration: none;
}

.-bold {
  font-weight: bold;
}
