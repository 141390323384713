/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: PageJump.module.scss
*
* @author: Panasonic, developer
*
*/

@import 'src/styles/common.scss';

.pageJump {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: $grey3;
}

.pageJumpInput {
  width: 40px;
  height: 40px;
  border: 1px solid $grey;
  margin-top: 0;
  margin-left: 5px;
  appearance: none;
  background: transparent;
  text-align: center;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    margin: 0;
    appearance: none;
  }
}
