/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: FormField.module.scss
*
* @author: Panasonic, developer
*
*/

@import 'src/styles/common.scss';

.wrapper {
  position: relative;
  padding-bottom: 20px;
}

.label {
  display: inline-flex;
  margin-bottom: 10px;
  font-size: 13px;
}

.labelText,
.descriptionText {
  font-weight: normal;
  font-size: 13px;
  color: $dark-grey;
}

.descriptionText {
  font-size: 13px;
}

.viewMode {
  input,
  textarea {
    height: auto;
    padding: 0;
    border: 0;
    line-height: normal;
  }

  textarea {
    min-height: auto;
  }

  .label {
    color: $grey;
  }
}

.asterisk {
  padding: 0 2px;
  font-size: 16px;
}

.error {
  input,
  textarea,
  select,
  * {
    border-color: $red;
  }
}

.container {
  width: 100%;
}
