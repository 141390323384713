/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: PageHeader.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.header {
  padding: 20px 0;
  background: #fff;
  border-bottom: 1px solid $grey;

  &.isInSummary {
    border-bottom: none;
  }
}

.label {
  font-family: DINOT, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-transform: capitalize;
  word-break: break-all;
  color: $dark-grey;
}

div .summaryLabel {
  color: $light-grey;
}

div.summaryDescription {
  color: $half-transparent;
}

.description {
  padding-top: 0;
  color: $caption-grey;
  font-size: 13px;
  line-height: 20px;
}

.content {
  position: relative;
  margin-top: 0;
  composes: route-container from global;
}

.summary {
  width: 100%;
}

.breadcrumbs {
  margin-bottom: 17px;
  line-height: 20px;
}
