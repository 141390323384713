@import 'src/styles/common.scss';

.wrapper {
  height: 70px;
  display: flex;
  align-items: center;
  background-color: $primary;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  composes: route-container from global;
}

.left {
  display: flex;
  align-items: center;
}
