/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: fonts.scss
 *
 * @author: Panasonic, developer
 */

@font-face {
  font-family: DINOT;
  font-style: normal;
  font-weight: normal;
  src: url('../services/fonts/DINOT.otf') format('opentype');
}

@font-face {
  font-family: DINOT;
  font-style: normal;
  font-weight: 200;
  src: url('../services/fonts/DINOT-Light.otf') format('opentype');
}

@font-face {
  font-family: DINOT;
  font-style: normal;
  font-weight: 500;
  src: url('../services/fonts/DINOT-Medium.otf') format('opentype');
}

@font-face {
  font-family: DINOT;
  font-style: normal;
  font-weight: 700;
  src: url('../services/fonts/DINOT-Bold.otf') format('opentype');
}

@font-face {
  font-family: DINOT;
  font-style: normal;
  font-weight: 900;
  src: url('../services/fonts/DINOT-Black.otf') format('opentype');
}
