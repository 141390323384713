/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: DialogBoilerplate.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.wrapper {
  .backdrop {
    background-color: $dark-backdrop;
  }
}

.header {
  min-height: 80px;
  padding: 0 20px;
  border-bottom: 1px solid $grey;
  font-weight: bold;
  line-height: 80px;
}

.title {
  font-size: 18px !important;
}

.content {
  position: relative;
  width: 600px;
  flex: 1 1 auto;
  padding: 20px;
  background: $light-grey;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  border-top: 1px solid $grey;
  margin: 0 !important;
}

button.btn {
  min-width: 150px !important;
  margin: 0;
  text-align: center;

  &:not(:first-child) {
    margin-left: 20px;
  }
}

.closeIcon {
  display: block;
}
