/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: Password.module.scss
*
* @author: Panasonic, developer
*
*/

@import 'src/styles/common.scss';

.inputWrapper {
  position: relative;
  display: flex;
}

.error {
  border-color: $red;
}

.input {
  padding-right: 40px;
}

.togglePassword {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  justify-content: center;
  background-color: transparent;
  border: none;
}
