@import 'src/styles/_colors.scss';

.wrapper {
  border-bottom: 1px solid $grey;
}

.item {
  padding: 0 20px 19px 0;
  cursor: pointer;
  flex-grow: 1;

  &:hover {
    .title {
      color: $primary-hover;
    }
  }

  &:not(:first-child) {
    padding-left: 20px;
  }
}

.active {
  color: $primary;
}

.indicator {
  height: 1px !important;
}
