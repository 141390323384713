/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: ResetPassword.module.scss
*
* @author: Panasonic, developer
*
*/

@import 'src/styles/common.scss';

.linkWrapper {
  margin-top: 20px;
}

.link {
  color: $primary;
  font-size: 13px;
  text-decoration: none;
}

.btn {
  min-width: 140px;
  margin: 0 auto;
}

.form {
  position: relative;
  display: flex;
  width: 300px;
  flex-direction: column;
  align-items: center;
}

@keyframes fade_in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.popper {
  z-index: 1;
  width: 100%;
  margin-top: 10px;
  animation: fade_in 0.2s forwards;
}
