@mixin heading {
  font-weight: bold;
}

.body1 {
  font-size: 12px;
}

.heading3 {
  @include heading;

  font-size: 16px;
}
