/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Breadcrumbs.module.scss
 *
 * @author: Panasonic, developer
 */
@import 'src/styles/common.scss';

.breadcrumbLink {
  word-break: break-all;
}

span .title {
  max-width: 150px;
}

.separator {
  font-size: 12px;
  margin: 0 3px 0 5px;
  color: $caption-grey;
}
