@import 'fonts.scss';
@import '_modificators.scss';

.route-container {
  width: 95%;
  margin: 0 auto;

  &.with-padding {
    padding: 20px 0;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1140px;
    margin: 0 auto;
  }
}

.with-top-padding {
  padding-top: 20px;
}

.full-width {
  width: 100%;
}

.rt-button-cell {
  display: flex;
  align-items: center;
  justify-content: center !important;
  padding: 0 !important;
}

.mdi-icon[width='24'] {
  width: 20px;
  height: 20px;
}
