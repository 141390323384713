/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: TablePagination.module.scss
*
* @author: Panasonic, developer
*
*/

@import 'src/styles/common.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
  color: $grey;

  .pageSize {
    width: 80px;
    min-width: 80px;
    background: none;
    color: $grey;
    font-size: 13px;

    &Select {
      width: auto;
      flex-grow: 1;
      background: transparent;
    }

    &::before {
      content: none;
    }
  }

  .summary {
    margin-left: 15px;
    color: $grey3;
    font-size: 12px;
  }
}

.subTable {
  padding-top: 10px;
  padding-bottom: 0;
  background: $bg-main;
}

.pagination {
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin: 0;
  font-size: 13px;
  list-style: none;

  .page,
  .previousPage,
  .nextPage {
    cursor: pointer;
  }

  .breakPage,
  .page {
    min-width: 16px;
    padding: 0 2px;
    text-align: center;
  }

  .page {
    color: $primary;
    line-height: 20px;
    outline: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .previousPage,
  .nextPage {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    width: 40px;
    min-width: auto;
    height: 40px;
    padding: 0;
    border: 1px solid $grey;
    border-radius: 0;
    color: $dark-grey;
  }

  .activePage {
    color: $caption-grey;
  }

  .disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .previousPage {
    margin-right: 10px;
  }

  .nextPage {
    margin-left: 10px;
  }

  .pageLink,
  .previousLink,
  .nextLink {
    outline: none;
  }

  .breakPage,
  .activePage {
    pointer-events: none;
  }

  .previousLink,
  .nextLink {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}
