/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: ThComponent.module.scss
*
* @author: Panasonic, developer
*
*/

@import 'src/styles/common.scss';

:global(.ReactTable .rt-thead) {
  .th {
    position: static;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.contentWrapper {
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: space-between;
}

.sort {
  &Asc {
    box-shadow: inset 0 3px 0 0 $primary !important;
  }

  &Desc {
    box-shadow: inset 0 -3px 0 0 $primary !important;
  }

  &Icon {
    position: absolute;
    right: 0;
  }
}
