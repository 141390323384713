/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: FormPanelHeader.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.header {
  display: flex;
  height: 80px;
  padding: 0 20px;
  align-items: center;
  border-bottom: 1px solid $grey;
  background: $white;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;

  &.filled {
    background-color: $light-grey;
  }
}

.label {
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
}

.description {
  padding: 0;
  color: $caption-grey;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
}
