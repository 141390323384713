/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: Login.module.scss
*
* @author: Panasonic, developer
*
*/

@import 'src/styles/common.scss';

.linkWrapper {
  margin-top: 20px;
}

.link {
  color: $primary;
  font-size: 13px;
  text-decoration: none;
}

.btn {
  min-width: 140px;
  margin: 10px auto 0;
}

.form {
  display: flex;
  width: 300px;
  flex-direction: column;
  align-items: center;
}

.fields {
  position: relative;
}
