/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: TextareaField.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.default {
  width: 100%;
  min-height: 100px;
  padding: 7px;
  border: 1px solid $grey;
  border-radius: 2px;
  font-size: 13px;
  outline: none;
  resize: none;
}

.view {
  display: inline-block;
  width: auto;
  min-height: auto;
  padding: 0;
  border: 0;
  word-break: break-word;
}
