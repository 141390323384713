@import 'src/styles/common.scss';

.wrapper {
  position: relative;
  display: flex;
  height: 70px;
  margin-left: 5px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    height: 30px;
    width: 1px;
    margin-top: -15px;
    background: rgba($white, 0.2);
  }
}

.currentPageTitle {
  color: $white;
  margin: 0;
}

.currentPageWrapper {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.navItemsWrapper {
  position: relative;
  display: flex;
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  margin-left: 20px;

  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 1px;
    height: 30px;
    background: $white;
    content: '';
    opacity: 0.2;
    transform: translateY(-50%);
  }
}
