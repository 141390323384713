/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: PanelWithIcon.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.wrapper {
  width: 100%;
  padding: 20px;
  font-size: 13px;
  text-align: center;
}

.iconWrapper {
  width: 140px;
  height: 140px;
  border: 1px solid $grey;
  margin: auto;
  background: $white;
  border-radius: 50%;
  composes: centered from global;
}

.icon {
  color: $grey;
}

.label {
  display: block;
  padding: 20px 0 10px;
  color: $dark-grey;
  font-size: 20px;
  font-weight: 500;
}

.description {
  max-width: 360px;
  margin: auto;
  color: $grey;
  font-size: 13px;
  font-weight: 200;
  line-height: 18px;
  white-space: normal;
  word-break: normal;
}
