@import 'src/styles/common.scss';

.wrapper {
  width: 400px;
  margin: auto;
  padding-top: 20px;
  text-align: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  background: #eff3fc;
  border-radius: 50%;
  margin: auto auto 20px;
}

.title {
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.description {
  font-size: 13px;
  color: $caption-grey;
  line-height: 20px;
}
