/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: SummaryHeader.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

$border: 1px solid $grey;

.wrapper {
  width: 100%;
  border: $border;
  background: $white;
}

.top {
  padding: 20px;
}

.title {
  font-size: 13px;
  font-weight: 700;
}

.description {
  color: $caption-grey;
}

.items {
  display: flex;
  flex-wrap: wrap;
}

.value {
  max-height: 40px;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
}

.item {
  flex: 1;
  padding: 20px;
  border-top: $border;

  &:nth-last-of-type(3) {
    flex-basis: 100%;
  }

  &:not(:nth-last-of-type(3)):not(:last-of-type) {
    border-right: $border;
  }
}

.devicesButton {
  font-size: 13px;
  font-weight: 500;
}
