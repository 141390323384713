/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: Input.module.scss
*
* @author: Panasonic, developer
*
*/

@import 'src/styles/common.scss';

.input {
  display: block;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 7px;
  border: 1px solid $grey;
  border-radius: 2px;
  color: $dark-grey;
  font-size: 13px;
  outline: none;

  &::placeholder {
    color: $caption-grey;
  }
}

.inputDisabled {
  background: $bg-disabled;
}
