@import 'src/styles/common.scss';

.wrapper {
  display: flex;
  align-items: center;
  height: $footerHeight;
  background-color: $primary;
}

.content {
  display: flex;
  composes: route-container from global;
}

.copyright {
  width: 290px;
  margin-left: auto;
  color: $light-blue;
}
