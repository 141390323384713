/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: PanelItem.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.item {
  composes: left-aligned-items from global;
  font-size: 13px;
  line-height: 20px;
}

.key {
  width: 50%;
  padding-right: 10px;
  color: $grey;
}

.value {
  width: 50%;
}
