@import 'src/styles/_colors.scss';

$border: 1px solid $grey;

.wrapper {
  background-color: $main-bgc;
  border: $border;
  padding-bottom: 20px;
}

.item {
  padding: 10px 20px;
  border-bottom: $border;

  &:not(:nth-child(2n)) {
    border-right: $border;
  }
}

.header {
  padding: 20px;
  border-bottom: $border;
  border-right: 0;
}
