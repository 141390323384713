/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Checklist.module.scss
 *
 * @author: Panasonic, developer
 *
 */

@import 'src/styles/common.scss';

.wrapper {
  padding: 8px 10px 10px;
  border: 1px solid $grey;
  background: $white;
  border-radius: 2px;
  box-shadow: 0 5px 10px 0 rgba(170, 170, 170, 0.2);
}

.label {
  color: $dark-grey;
  font-size: 11px;
  font-weight: bold;
  line-height: 20px;
}
