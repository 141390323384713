/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: IncidentServices.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.emptyState {
  margin: 30px 0;
}

.emptyStateDescription {
  color: $caption-grey;
}

.addButton {
  width: 200px;
}

.addButtonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
