/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: SimplePage.module.scss
*
* @author: Panasonic, developer
*
*/

@import 'src/styles/common.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 11;
  background: $primary;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.9);

  &.withHeader {
    top: 70px;
    height: calc(100vh - 70px);
  }

  &.withFooter {
    bottom: 70px;
    height: auto;
  }
}

.card {
  width: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background: $white;
  border: 1px solid $grey;
}

.label {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}

.description {
  color: #999999;
  font-size: 13px;
}

.labelRoot {
  max-width: 100%;
  margin: 20px 0;
  text-align: center;
}

.image {
  width: 64px;
  height: 64px;
}

.button {
  width: 140px;
  justify-content: center;
}

.childrenWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
