$primary: #0041c0;
$primary-hover: #063693;

$white: #fff;
$grey: #eee;
$caption-grey: #999;
$grey3: #717171;
$dark-grey: #333;
$black: #000;

$light-grey6: #ddd;
$bg-main: #fafbfe;
$light-blue: #92b2f0;
$success-green: #1fd487;
$error-red: #fe5630;
$main-bgc: #fafbfe;
$bg-disabled: rgba($grey, 0.2);
$red: #ff5630;
$black-main: #161616;
$primary-dark: #04389f;
$secondary: #f7f9fa;
$default-main: #979a9a;
$default-high-transparent: rgba(151, 154, 154, 0.1);
$high-transparent: rgba(247, 249, 250, 0.1);
$half-transparent: rgba(247, 249, 250, 0.5);
$highlight-text-bg: rgba(33, 212, 136, 0.3);
$dark-backdrop: rgba(0, 0, 0, 0.8);
$default-light: #cbcccc;
$black-light: #494949;
$primary-light: #2770ff;
$light-grey: #f9fafc;
$light-grey3: #ccc;
$light-grey7: #e1e1e1;
$light-blue: #eff3fc;
