/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Breadcrumb.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.link {
  min-width: auto;
  padding: 0;
  font-size: 12px;
  letter-spacing: normal;
  text-transform: capitalize;
  display: inline-flex;
  color: $primary;
  text-decoration: none;

  &.disabled {
    color: $grey;
    opacity: 1;
    pointer-events: none;
  }
}

.text {
  display: inline-flex;
  color: $caption-grey;
  font-size: 12px;
}
