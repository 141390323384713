@import 'src/styles/_colors.scss';

.wrapper {
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-left: 15px;
}

.text {
  color: $caption-grey;
  margin-left: 5px;
}
