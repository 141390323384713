/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: FormPanel.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.content {
  padding: 20px;
  background-color: $white;
}

.required {
  &::after {
    content: '*';
  }
}

.withMargins {
  margin: 20px 0;
}
