/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: FormDialog.module.scss
*
* @author: Panasonic, developer
*
*/

.paperRoot {
  width: auto !important;
}

.dialogContent {
  width: 600px;
  padding: 0;
}

.formWrapper {
  padding: 20px;
}

.error {
  font-size: 12px !important;
}
