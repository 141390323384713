/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Actions.module.scss
 *
 * @author: Panasonic, developer
 */

.container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.buttonWrapper {
  button + button {
    margin-left: 20px;
  }
}

.button {
  width: 160px;
}
