/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Search.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.search {
  display: flex;
  overflow: hidden;
  height: 40px;
  border: 1px solid $grey !important;
  border-bottom: 3px solid transparent;
  background-color: $white;
  border-radius: 2px;

  &Invalid {
    border-bottom: 3px solid $red;
  }

  & > * {
    outline: 0;
  }
}

.input {
  width: 100%;
  padding-left: 10px;
  border: 0;
  background-color: inherit;
  color: $dark-grey;
  font-size: 13px;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: $default-main;
}
