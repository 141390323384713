/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: MenuWithAnchor.module.scss
 *
 * @author: Panasonic, developer
 */

div.paper {
  border-radius: 2px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
