/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: SummaryPanel.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.summaryPanel {
  width: 100%;
  border: 1px solid $grey;
  margin-top: 20px;
  background: $white;
}
