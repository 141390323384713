/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: EmptyState.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.panel {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 430px;
  padding: 0;

  &WithAction {
    margin-bottom: 20px;
  }
}

.description {
  padding-top: 0;
  color: #999;
  font-size: 13px;
  white-space: normal;
  word-break: normal;
}

.label {
  margin: 20px 0 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.icon {
  display: flex;
  height: 140px;
  width: 140px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: $light-blue;
}
