/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: SelectWithLabel.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

$padding: 7px;

.wrapper {
  position: relative;
  display: flex;
  width: 100%;
  background-color: $white;
  border-radius: 2px;
  cursor: pointer;

  &.disabled {
    background-color: $bg-disabled;
    pointer-events: none;
  }

  &.wrapperViewMode {
    margin-top: -3px;
    background-color: transparent;
    cursor: default;
  }
}

.label {
  display: block;
  margin-bottom: 10px;
  font-size: 13px;
}

.default {
  overflow: hidden;
  width: 100%;
  min-width: 100px;
  height: 40px;
  border: 1px solid $grey;
  appearance: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 13px;
  outline: none;
  transition: background-color 0.1s linear 0s;

  select {
    height: 40px;
    box-sizing: border-box;

    &:focus {
      background: none;
    }
  }

  &:hover {
    border-color: $light-grey7;
  }

  &.open {
    border-color: $light-grey3;
  }
}

.viewMode {
  overflow: visible;
  height: auto;
  padding: 0;
  border: 0;
}

div.select {
  color: $dark-grey;
}

div.selectViewMode {
  overflow: visible;
  height: auto;
  min-height: 0;
  padding: 0;
  color: $dark-grey;
  line-height: normal;
  user-select: auto;
}

div.rootViewMode {
  font-size: 13px;
  line-height: normal;
}

.defaultOption {
  color: $grey;
}

.error {
  border-color: $red;
}

.disabledIcon {
  color: $grey !important;
}

.emptyStateRoot {
  margin-top: 20px;
}

div.emptyStateLabel {
  margin-top: 5px;
  color: $grey;
  font-size: 13px;
}

div.selectMenuPaper {
  max-width: 300px;
  border: 1px solid $light-grey3;
  margin-top: 10px;
  border-radius: 2px;
  box-shadow: 0 5px 10px 0 rgba(170, 170, 170, 0.2);
}
