@import 'src/styles/_colors.scss';

$border: 1px solid $grey;

.wrapper {
  margin-top: 20px;
  background-color: $white;
  border: $border;
}

.item {
  padding: 10px 20px;
  border-bottom: $border;

  &:not(:nth-child(2n)) {
    border-right: $border;
  }
}

.header {
  padding: 20px;
  border-bottom: $border;
  border-right: 0;
}
