/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Spinner.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.inBlock {
  padding: 50px;
}

.inBackdrop {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $half-transparent;
}
