/**
* PASA Confidentiality Notice:
* This source code and information contained herewith may be legally privileged and confidential
* Any dissemination, distribution or copy of this source code is strictly prohibited.
*
* Copyright (C) 2019, Panasonic Automotive Systems Company of America
*                     All Rights Reserved
*
*
* @file: PaginatedTable.module.scss
*
* @author: Panasonic, developer
*
*/

@import 'src/styles/common.scss';

.wrapper {
  width: 100%;

  &.noTools {
    margin-top: 20px;
  }
}

.tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;

  padding: 20px 0;

  & > * {
    &:not(:first-child):not(.search) {
      margin-left: 20px;
    }
  }
}

.search {
  max-width: 125px;
}

.withCustomTools {
  padding-bottom: 20px;
}
