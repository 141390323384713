/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: PromptDialog.module.scss
 *
 * @author: Panasonic, developer
 */

@import 'src/styles/common.scss';

.description {
  padding-bottom: 20px;
  font-size: 13px;

  &Line {
    overflow: hidden;
    margin: 0;
    word-break: break-word;
  }
}

.field {
  margin-bottom: -20px;
}

.input {
  min-height: 80px;
}
